<template>
    <div>
        <div class="navMenu">
            <img class="logo" src="../../assets/logo2.png" @click="goHome">
            <div>
                <img class="list" @click="openMenu" src="../../assets/images/phonelist.png" alt="">
                <div v-if="isMenu" class="menu">
                    <el-menu :active="activeIndex" :default-active="activeIndex" class="el-menu-demo" text-color="#000"
                        active-text-color="#0adfad">
                        <template v-for="item in listMenu">
                            <el-submenu v-if="item.children && item.children.length > 0" :index="item.link + ''"
                                :key="item.id">
                                <template slot="title">
                                    <span>{{ item.menuName }}</span>
                                </template>
                                <el-menu-item :index="item.path || ''" v-for="second in item.children" :key="second.id"
                                    @click="handleMenuClick(second.link)">
                                    {{ second.menuName }}
                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="item.link" @click="handleMenuClick(item.link)">{{ item.menuName
                            }}</el-menu-item>
                        </template>
                    </el-menu>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: '1',
            isMenu: false,
            listMenu: []
        }
    },
    mounted() {
        this.getMobileMenu()
    },
    methods: {
        goHome() {
            this.$router.push('/phone-home')
            this.isMenu = false
        },
        openMenu() {
            this.isMenu = !this.isMenu
        },
        //获取手机端菜单列表
        getMobileMenu() {
            this.$sys.menuList({
                locationId: 51,
                pageId: 1
            }).then(res => {
                if (res.code == 200) {
                    console.log('phone端菜单列表', res);
                    this.listMenu = res.data
                }
            })
        },
        handleMenuClick(path) {
            let currentPath = this.$route.path
            if (currentPath != path) {
                this.$router.push(path)
                this.isMenu = false
            }
            if(this.$route.path=='/phone-home'){
                this.isMenu = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.navMenu {
    width: 100vw;
    height: 3vh;
    // background-color: black;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 9999;
    padding-top: 1rem;

    .logo {
        width: 16vw;
        height: 1.8rem;
        // vertical-align: middle;
        padding-left: 6vw;
    }

    div {
        position: relative;

        .list {
            width: 8vw;
            height: 1.8rem;
            vertical-align: middle;
            padding-right: 6vw;
        }

        .menu {
            width: 40vw;
            height: 13rem;
            position: absolute;
            top: 2rem;
            right: 0;
            background-color: transparent !important;

            .el-menu--horizontal {
                background-color: transparent !important
            }

            .el-menu--horizontal>.el-menu-item {

                height: 3.6rem;
                font-size: 24px;
                outline: 0 !important;
                background-color: transparent !important
            }

            .el-menu--horizontal>.el-menu-item.is-active {
                color: #0adfad;
                background-color: transparent !important
            }

            .el-submenu,
            .el-menu--horizontal>.el-submenu.is-active {
                font-size: 24px;
                background-color: transparent !important
            }

            .el-submenu .el-submenu__title.is-opened {
                background-color: transparent !important;
                font-size: 16px;
            }

            .el-submenu.is-opened {
                background-color: transparent !important;
            }

            .el-menu--horizontal>.el-submenu .el-submenu__title {
                background-color: transparent !important;
            }

        }
    }




}</style>