import Vue from "vue";
import Information from '@/components/common/Information'
import NavMenu from '@/components/common/NavMenu.vue'
import TabBar from '@/components/common/TabBar.vue'
import Footer from '@/components/common/Footer.vue'
import MobileNavMenu from '@/components/common/MobileNavMemu.vue'
import MobileFooter from '@/components/common/MobileFooter.vue'
// Vue.component('CFInfo',Information)
Vue.component('CNavMenu',NavMenu)
// Vue.component('TabBar',TabBar)
Vue.component('CFooter',Footer)
Vue.component('CMobileMenu',MobileNavMenu);
Vue.component('CMobileFooter',MobileFooter)