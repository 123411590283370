import request from "@/utils/request";
export default {
    /**
     * 获取banner列表
     * @returns
     */
    bannerList: (data) => request.get('/webservice/banner/list', data),
    /**
     * 获取菜单列表
     * @returns
     */
    menuList: (data) => request.get('/webservice/menu/list', data),
    /**
     * 获取资讯列表
     * @returns
     */
    newsList: (data) => request.get('/webservice/news/list', data),
    /**
     * 根据ID获取资讯详情
     * @returns
     */
    newsDetail: (data) => request.get('/webservice/news/detail', data),
    /**
     * 获取项目案例列表
     * @returns
     */
    projectList: (data) => request.get('/webservice/project/list', data),
    /**
     * 获取工具栏列表
     * @returns
     */
    toolbarList: (data) => request.get('/webservice/toolbar/list', data),
    /**
     * 获取官网页面内容列表
     * @returns
     */
    pageContentList: (data) => request.get('/webservice/page/content/list', data),
    /**
     * 充电数据
     * @returns
     */
    chargingdataData: (data) => request.get('/webservice/chargingdata/data', data),

}