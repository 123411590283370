<template>
    <div>
        <div class="infoMenu" @click="goDetail">
            <div class="title">
                <p>蓄力以待 充向未来</p>
                <span>亘享充智能管理平台暨新品发布会圆满举行……</span>
            </div>
            <img src="../../assets/发布会.png" alt="">
        </div>
        <div class="infoMenu" @click="goNew">
            <div class="title">
                <p>充电站新宠—光储充</p>
                <span>随着电动汽车应用的普及，市场对充电站的需求逐渐增多，电网的挑战也越来越大，这就对充电站的控制智能化和运行可靠性提出了更高的要求……</span>
            </div>
            <img src="../../assets/光储.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        goDetail(){
            location.href='https://mp.weixin.qq.com/s/JlAcCfJ2_08LyazpkFae-g'
        },
        goNew(){
            location.href='https://mp.weixin.qq.com/s/FdxoomCjK1fuOF0AMiqYyg'
        }
    }
}
</script>

<style lang="scss" scoped>
.infoMenu {
    width: 90vw;
    height: 80px;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dbdbdb;
    .title {
        width: 60vw;
        height: 75px;
        p {
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        span {
            width: 60vw;
            font-size: 12px;
            margin-top: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 20px;
            /* 这里的行高要和容器高度相对应 */
            text-overflow: ellipsis;
        }
    }

    img {
        width: 25vw;
        height:75px;
        margin-top: 8px;
    }
}</style>