<template>
    <div>
        <footer>
            <div class="cooperator">
                <div class="cooperator-product">
                    <div style="width: 46rem;height: 3rem; border-right: 1px solid #b3b0b0;color: #fff; ">
                        <p v-for="item in footerList">
                            <router-link :to="item.link" style="color: #fff;">
                                {{ item.name }}<span>: &nbsp;{{ item.message }}</span>
                            </router-link>
                        </p>
                    </div>
                    <div style="width: 12vw;display: flex;justify-content: space-around;">
                        <div style="display: flex;flex-direction: column;">
                            <img style="width: 5rem;height: 5rem;margin-bottom: 0.2rem;" src="../../assets/program.jpg"
                                alt="">
                            <span style="color: #b3b0b0;font-size: 0.9rem;text-align: center;">扫一扫关注</span>
                        </div>
                        <div style="display: flex;flex-direction: column;">
                            <img style="width: 5rem;height: 5rem;margin-bottom: 0.2rem;"
                                src="../../assets/public account.jpg" alt="">
                            <span style="color: #b3b0b0;font-size: 0.9rem;text-align: center;">扫一扫关注</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bottom">
                <p>备案号：<a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2024049214号
                    </a></p>
                <div id="wangAn">
                    <img src="../../assets/wangan.png" alt="">
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012202001492"
                        target="_blank">川公网安备51012202001492号
                    </a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import MapContainer from './MapContainer.vue';
export default {
    components: {
        MapContainer
    },
    data() {
        return {
            footerList: []
        }
    },
    mounted() {
        this.getFooterList()
    },
    methods: {
        getFooterList() {
            this.$sys.toolbarList({}).then(res => {
                if (res.code == 200) {
                    this.footerList = res.data;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    background-color: #000;

    .cooperator {
        width: 100vw;
        height: 8rem;
        background-color: #313131;
        display: flex;
        align-items: center;

        .cooperator-product {
            width: 76vw;
            height: 6rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

    }

    .bottom {
        width: 70vw;
        height: 3.75rem;
        background-color: #000;
        margin: 0 auto;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 3.75rem;

        #wangAn {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        a {
            text-decoration: none;
            color: #fff;
        }

    }
}
</style>