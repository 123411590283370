<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  name: "App",
  mounted() {
    if (this._isMobile()) {
      //跳转手机路由
      this.$router.replace('/phone-home');
    } else { 
      //跳转pc端
      this.$router.replace("/pc-home")
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  }
}
</script>
<style>
div,
p,
h1,
h2,
h3,
ul,
li,
body,
html,
img {
  margin: 0;
  padding: 0
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  /* 去除下划线 */
}

.el-header {
  margin: 0;
  padding: 0;
  height: auto ! important;
  background-color: rgba(0, 0, 0, 0);
}

.el-main {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.el-footer {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

</style>
