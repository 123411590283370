<template>
    <div>
        <div class="video">
            <div class="information">
                <div style="width: 23vw;border-right: 1px solid #a7a7a7;">
                    <img style="max-width: 18vw;height: 2rem;" src="../../assets/logo2.png" alt=""
                        @click="goHome">
                </div>
                <div style="width: 62vw;display: flex;justify-content: space-around;">
                    <img style="width: 7vw;height: 1.2rem;" src="../../assets/images/phone/4/微信.png" alt=""
                        @click="goweixin">
                    <img style="width: 8vw;height: 1.5rem;" src="../../assets/images/phone/4/视频号.png" alt=""
                        @click="goVideo">
                    <img style="width: 12vw;height: 1rem;" src="../../assets/images/phone/4/小红书.png" alt="" @click="goxhs">
                    <img style="width: 7vw;height: 1.2rem;" src="../../assets/images/phone/4/新浪微博.png" alt=""
                        @click="goweibo">
                </div>
            </div>
            <div class="accordion">
                <div v-for="(item, index) in items" :key="index" class="itemMenu"
                    :class="{ active: activeIndex === index, expandable: item.children }" @click="goFirstPage(item)">
                    <div @click="toggleActive(index)" class="">
                        {{ item.title }}
                        <i class="el-icon-arrow-down" v-if="item.children" style="margin-left: 56vw;"></i>
                    </div>
                    <div v-show="activeIndex === index">
                        <template v-if="item.children">
                            <div v-for="(child, idx) in item.children" :key="idx">
                                <router-link :to="child.path" style="color: #a7a7a7;">{{ child.title }}</router-link>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <p>备案号：<a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2024049214号
                    </a></p>
                <div id="wangAn">
                    <img src="../../assets/wangan.png" alt="">
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012202001492"
                        target="_blank">川公网安备51012202001492号
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: null,
            items: [
                {
                    title: "关于亘享充",
                    path: "/phone-about"
                },
                {
                    title: "解决方案",
                    children: [
                        {
                            id: 1,
                            title: '户外专用充电站',
                            path: '/phone-outdoordedicated',
                        },
                        {
                            id: 2,
                            title: '户外公共充电站',
                            path: '/phone-outdoorpublic',
                        },
                        {
                            id: 3,
                            title: '户内专用充电站',
                            path: '/phone-indoordedicated',
                        },
                        {
                            id: 4,
                            title: '户内公共充电站',
                            path: '/phone-indoorpublic',
                        },
                    ]
                },
                {
                    title: "生态合作", children: [
                        {
                            id: 3 - 1,
                            title: 'SAAS平台',
                            path: '/phone-saas',
                        },
                        {
                            id: 3 - 2,
                            title: '互联互通平台',
                            path: '/phone-connected',
                        }
                    ]
                },
                {
                    title: "更多资讯",
                    path: '/phone-moreinfo'
                },
                {
                    title: "联系我们",
                    path: '',
                    children: [
                        {
                            id: 1,
                            title: '联系电话:15390421639',
                            path: '/phone-contactus',
                        },
                        {
                            id: 2,
                            title: '电子邮箱:chargingpleasure@163.com',
                            path: '/phone-contactus',
                        },
                        {
                            id: 3,
                            title: '公司地址:四川省成都市郫都区德源镇（菁蓉镇）大禹东路66号',
                            path: '/phone-contactus',
                        },
                    ]
                }
            ]
        }
    },
    mounted() {
    },
    methods: {
        goHome() {
            this.$router.push('/phone-home')
        },
        goVideo() {
            // location.href = 'https://weixin.qq.com/sph/AN0yVb'
        },
        goweixin() {
            location.href = 'https://mp.weixin.qq.com/mp/homepage?__biz=MzkyNTQzNTQ4NQ==&hid=1&sn=80e295cceedd522d3bbd4185b811a129'
        },
        //跳转小红书
        goxhs() {
            location.href = 'https://www.xiaohongshu.com/user/profile/64c9ac82000000000b0099b4'
        },
        goweibo() {
            location.href = 'https://weibo.com/u/7858715432'
        },
        goFirstPage(item) {
            
            if (item.path) {
                this.$router.push(item.path)
                console.log('跳转', item.path);
            }
        },
        toggleActive(index) {
            if (index === this.activeIndex) {
                // 折叠当前项
                this.activeIndex = null;
            } else if (this.items[index].children) {
                // 展开含有子项的项
                this.activeIndex = index;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.video {
    width: 100vw;
    height: auto;
    background-image: url('../../assets/images/首页移动端info.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 2rem;

    .information {
        width: 85vw;
        height: 10vh;
        background-color: transparent;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .accordion {
        width: 82vw;
        height: auto !important;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        color: #fff;
        margin-top: 1rem;

        .itemMenu {
            width: 100%;
            // height: 2.6rem;
            border-bottom: 0.5px solid #555555;
            line-height: 2.6rem;
            color: #dbdbdb;
            font-size: 14px;
            letter-spacing: 2px;
            overflow-y: auto;
        }
    }

    .active {
        background-color: transparent;
        color: #0adfad;
    }

    .expandable i {
        margin-left: auto;
    }

    .bottom {
        width: 100vw;
        height: auto;
        margin: 0 auto;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.5rem;
        font-size: 12px;
        padding-top: 0.5rem;
        padding-bottom: 1rem;

        #wangAn {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        a {
            text-decoration: none;
            color: #fff;
        }

    }
}
</style>