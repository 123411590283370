<template>
    <div>
        您访问的页面找不到了，请检查链接是否正确
        <h3>
            <router-link to="/pc-home">去首页</router-link>
        </h3>

    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped></style>