import axios from 'axios'
import { Message, MessageBox } from 'element-ui'

/**
 * 配置基础路径
 */
axios.defaults.baseURL = process.env.VUE_APP_SERVER_PATH
//处理返回值
axios.interceptors.response.use((res) => {
    // console.log(res, 'res接口返回');
    const headers = res.headers;
    const token = headers['next_token'];
    sessionStorage.setItem('myHeaderData', JSON.stringify(token))
    if (res.status != 200) {
        Message.error('接口调用失败')
        return res.data
    } else {
        return res.data
    }
}, err => {
    console.log('err', err);
})

//请求拦截器
axios.interceptors.request.use((req) => {
    // console.log("111request", req);
    const token = JSON.parse(sessionStorage.getItem('myHeaderData'))
    req.headers.csrf_token = token;
    return req;
}, err => {
    console.log("err", err);
})
//暴露请求方法
export default {
    post(url, data) {
        return axios({
            method: 'POST',
            url,
            data,
        })
    },
    get(url, data) {
        return axios({
            method: 'GET',
            url,
            params: data
        })
    }
}