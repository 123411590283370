<template>
    <div>
        <div class="menuList">
            <div v-for="item in menuList">
                <router-link :to="item.link" class="custom-router-link">
                    <img :src="getImageUrl(item)" :class="{ active: item.picked }" @click="getOperate(item)">
                    <span>{{ item.menuName }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menuList: [],
        }
    },
    mounted() {
        this.getPhoneList()
    },
    methods: {
        getImageUrl(item) {
            return item.picked===false ? item.icon : item.iconPick;
        },
        getOperate(item) {
            if (item.path === this.$route.path) {
                item.icon=item.icon;
                item.picked = !item.picked
            } else {
                item.icon=item.iconPick
                item.picked=item.picked
            }
        },
        getPhoneList() {
            this.$sys.menuList({
                locationId: 51,
                pageId: 1
            }).then(res => {
                if (res.code == 200) {
                    console.log('phone端菜单列表', res);
                    this.menuList = res.data
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.custom-router-link {
    text-decoration: none;
    /* 取消默认的文本颜色 */
    color: inherit;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.menuList {
    width: 100vw;
    height: 8vh;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    div {
        width: 25vw;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            width: 8vw;
            height: 50%;
        }

        span {
            font-size: 12px;
            color: #777;
        }
    }
}

.active {
    /* 定义选中状态的图标样式 */
    background-color: aqua;
}
</style>