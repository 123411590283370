import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router'
import store from './store'
import sys from './apis/sys';
import info from './apis/info'

Vue.use(ElementUI);
import moment from 'moment'
import Video from "video.js";
import "video.js/dist/video-js.min.css"
Vue.prototype.$video = Video
Vue.prototype.$moment = moment
Vue.prototype.$sys = sys;
Vue.prototype.$info = info;
import '@/components'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
new Router({
scrollBehavior (to, from, savedPosition) {
return { x: 0, y: 0 }
}
})


Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
