<template>
    <div>
        <div id="container"></div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
    securityJsCode: 'd1039e7cf986f9e380a50227f1f6bca7'
}

export default {
    data() {
        return {
            map: null
        }
    },
    mounted() {
        this.initMap()
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: '44282697eb6369c826c15b4b0b7bbf96',
                version: '2.0',
                plugins: ['AMap.Scale'],
            }).then((AMap) => {
                this.map = new AMap.Map("container", {  //设置地图容器
                    viewMode: '3D',  //是否为3D模式
                    zoom: 5, //初始化地图级别
                    center: [103.930298, 30.808996] //初始化地图坐标
                });
                this.map.addControl(new AMap.Scale())
                this.map.addControl(new AMap.ToolBar())
                this.map.addControl(new AMap.HawkEye())
                this.map.addControl(new AMap.MapType())
                this.map.addControl(new AMap.Geolocation())
            }).catch(e => {
                    console.log(e);
                })
        }

    }
}
</script>

<style lang="scss" scoped>
#container {
    padding: 0px;
    margin: 0px;
    width: 100px;
    height: 100px;
}
</style>