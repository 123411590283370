import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFind from '@/views/404/NotFind.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/layout/pc-home'
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/views/Layout.vue'),
    children: [
      //主页
      {
        path: '/pc-home',
        name: 'pc-home',
        component: () => import('@/views/pc/HomePage')
      },
      //更多资讯
      {
        path: '/pc-moreinfo',
        name: 'pc-moreinfo',
        component: () => import('@/views/pc/MoreInfoPage.vue')
      },
      {
        path: '/solution-outdedicated',
        name: 'solution-outdedicated',
        component: () => import('@/views/pc/solution/OutdoorDedicated.vue')
      },
      {
        path: '/solution-outpublic',
        name: 'solution-outpublic',
        component: () => import('@/views/pc/solution/OutdoorPublic.vue')
      },
      {
        path: '/solution-indedicated',
        name: 'solution-indedicated',
        component: () => import('@/views/pc/solution/IndoorDedicated.vue')
      },
      {
        path: '/solution-inpublic',
        name: '/solution-inpublic',
        component: () => import('@/views/pc/solution/IndoorPublic.vue')
      },
      //生态合作
      {
        path: '/pc-works',
        name: 'pc-works',
        component: () => import('@/views/pc/WorkPage.vue')
      },
      //合作详情
      {
        path: '/cooperation-saas',
        name: 'cooperation-saas',
        component: () => import('@/views/pc/cooperation/SaasCooperation.vue')
      },
      {
        path: '/cooperation-connected',
        name: 'cooperation-connected',
        component: () => import('@/views/pc/cooperation/ConnectedCooperation.vue')
      },
      //联系我们
      {
        path: '/pc-contact',
        name: 'pc-contact',
        component: () => import('@/views/pc/ContactUs.vue')
      },
      //关于亘享充
      {
        path: '/pc-about',
        name: 'pc-about',
        component: () => import('@/views/pc/AboutUs.vue')
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/phone/Home.vue'),
    children: [
      {
        path: '/phone-home',
        name: 'phone-home',
        component: () => import('@/views/phone/HomePage'),
      },
      {
        path: '/phone-saas',
        name: 'phone-saas',
        component: () => import('@/views/phone/cooperation/SaasCooperation.vue')
      },
      {
        path: '/phone-connected',
        name: 'phone-connected',
        component: () => import('@/views/phone/cooperation/ConnectedCooperation.vue')
      },
      {
        path: '/phone-about',
        name: 'phone-about',
        component: () => import('@/views/phone/AboutUs.vue'),
      },
      {
        path: '/phone-moreinfo',
        name: 'phone-moreinfo',
        component: () => import('@/views/phone/MoreInfoPage.vue'),
      },
      {
        path: '/phone-indoordedicated',
        name: 'phone-indoordedicated',
        component: () => import('@/views/phone/solution/IndoorDedicated.vue'),
      },
      {
        path: '/phone-indoorpublic',
        name: 'phone-indoorpublic',
        component: () => import('@/views/phone/solution/IndoorPublic.vue'),
      },
      {
        path: '/phone-outdoordedicated',
        name: 'phone-outdoordedicated',
        component: () => import('@/views/phone/solution/OutdoorDedicated.vue'),
      },
      {
        path: '/phone-outdoorpublic',
        name: 'phone-outdoorpublic',
        component: () => import('@/views/phone/solution/OutdoorPublic.vue'),
      },
      {
        path:'/phone-contactus',
        name:'phone-contactus',
        component:()=>import('@/views/phone/ContactUs.vue')
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: NotFind
  }
]

//实例化路由
const router = new VueRouter({
  mode: 'history', // 将模式设置为 “history”
  base: process.env.BASE_URL,
  routes
})

export default router
