<template>
    <div>
        <div class="menu">
            <div class="nav">
                <img class="logo" src="../../assets/logo2.png" @click="goHome">
                <el-menu :active="activeIndex" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" route
                    background-color="transparent" text-color="#dbdbdb" active-text-color="#0adfad" @select="selectItems">
                    <template v-for="item in listMenu">
                        <el-submenu v-if="item.children && item.children.length > 0" :index="item.link + ''"
                            style="height: 4rem !important;">
                            <template slot="title">
                                <span style="font-size: 24px !important;"> {{ item.menuName }}</span>
                            </template>
                            <el-menu-item :index="second.link || ''" v-for="second in item.children" :key="second.id"
                                @click="handleMenuClick(second.link, second.id, second.pageId)"
                                style="font-size: 18px;background-color: black;">{{ second.menuName
                                }}</el-menu-item>
                        </el-submenu>
                        <el-menu-item v-else :index="item.link" @click="goPage(item.link, item.id, item.pageId)">{{
                            item.menuName
                        }}</el-menu-item>
                    </template>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // activeIndex: '1',
            listMenu: [],
        }
    },
    mounted() {
        this.getMenuList()

    },
    computed: {
        activeIndex: {
            get() {
                return this.$route.path
            },
            set() {

            },
        }
    },
    methods: {
        //获取菜单列表
        getMenuList() {
            this.$sys.menuList({
                locationId: 1,
                pageId: 1
            }).then(res => {
                if (res.code == 200) {
                    this.listMenu = res.data
                    console.log('pc端菜单列表', res.data);
                }
            })
        },
        //二级页跳转传参
        handleMenuClick(path, id, pageId) {
            this.$router.push({
                path: path,
                query: {
                    id: id,
                    pageId: pageId
                }
            })
        },
        //一级页跳转传参
        goPage(path, pageId) {
            this.$router.push({
                path: path,
                query: {
                    pageId: pageId
                }
            })
        },
        //
        selectItems(index, indexPath) {
            this.activeIndex = index

        },
        goHome(){
            this.$router.push({
                path: '/pc-home'
            })
        }
    },
}

</script>

<style lang="scss" scoped>
.menu {
    width: 100vw;

    .nav {
        width: 95vw;
        height: 8rem;
        margin: 0 auto;
        margin-top: 1.25rem;
        display: flex;
        justify-content: space-between;

        .logo {
            width: 9rem;
            height: 4rem;
            margin-top: 1rem;
            margin-left: 4rem;
            vertical-align: middle;
        }

        .el-menu--horizontal {
            background-color: transparent !important;

        }

        .el-menu--horizontal>.el-menu-item {
            margin-top: 2rem;
            height: 3.6rem;
            font-size: 24px;
            outline: 0 !important;
            background-color: transparent !important;

        }

        .el-menu--horizontal>.el-menu-item.is-active {
            color: #0adfad;
            background-color: transparent !important;

        }

        .el-submenu,
        .el-menu--horizontal>.el-submenu.is-active {
            margin-top: 2rem;
            font-size: 24px;
            background-color: transparent !important;

        }

        .el-submenu .el-submenu__title.is-opened {
            background-color: transparent !important;
            font-size: 18px;
            color: #4e4e4e;
        }

        .el-menu--horizontal>.el-submenu .el-submenu__title {
            background-color: transparent !important;

        }

        .el-submenu .el-menu-item {
            background-color: rgba(0, 0, 0, 0.5) !important;
        }

    }
}

.menu .nav .el-menu--horizontal>.el-submenu .el-submenu__title {
    background-color: #000 !important;
    height: 5.6rem;

}
</style>